import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiFillEdit, AiFillDelete } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Cookies from "universal-cookie";
const Features = ({ url }) => {
  const [features, setFeatures] = useState([]);
  const [addValue, setAddValue] = useState("");
  const [add, setAdd] = useState(false);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const getFeatures = async () => {
    try {
      const response = await fetch(`${url}/features/all`);
      if (response.ok) {
        const data = await response.json();
        setFeatures(data.data.features);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createFeature = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${url}/features/create`, {
        method: "POST",
        body: JSON.stringify({ title: addValue }),
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setAdd(false);
        setAddValue("");
        getFeatures();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFeature = async (id) => {
    try {
      const response = await fetch(`${url}/features/${id}`, {
        method: "DELETE",
        headers: {
          authorization: token,
        },
      });
      if (response.ok) {
        getFeatures();
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getFeatures();
  }, []);

  const EditComponent = ({ feature }) => {
    const [editValue, setEditValue] = useState(feature ? feature.title : "");
    const [edit, setEdit] = useState(false);
    const editFeature = async (id) => {
      try {
        const response = await fetch(`${url}/features/${id}`, {
          method: "PATCH",
          body: JSON.stringify({ title: editValue }),
          headers: {
            authorization: token,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          setEdit(false);
          getFeatures();
          setEditValue("");
        }
      } catch (err) {
        console.log(err);
      }
    };
    return edit ? (
      <div className="d-flex align-items-center mx-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editFeature(feature._id);
          }}
        >
          <TextField
            value={editValue}
            sx={{ "& > input": { fontSize: "16px" } }}
            onChange={(e) => setEditValue(e.target.value)}
            id="standard-basic"
            label="Edit the feature"
            variant="standard"
          />
        </form>
        <div onClick={editFeature} className="icon">
          <AiOutlinePlus />
        </div>
      </div>
    ) : (
      <button
        style={{ textTransform: "capitalize" }}
        className={feature.selected ? "feature-selected" : null}
        id={feature._id}
        type="button"
        key={feature._id}
      >
        <div>{feature.title}</div>
        <div id="btns">
          <span onClick={() => setEdit(true)}>
            <AiFillEdit />
          </span>
          <span onClick={() => deleteFeature(feature._id)}>
            <AiFillDelete />
          </span>
        </div>
      </button>
    );
  };
  return (
    <div>
      <div className="select-features">
        <div className="features-header">
          <h3>All Features</h3>
          {add ? (
            <div className="d-flex align-items-center">
              <form onSubmit={createFeature}>
                <TextField
                  value={addValue}
                  sx={{ "& > input": { fontSize: "16px" } }}
                  onChange={(e) => setAddValue(e.target.value)}
                  id="standard-basic"
                  label="Create a new feature"
                  variant="standard"
                />
              </form>
              <div onClick={createFeature} className="icon">
                <AiOutlinePlus />
              </div>
            </div>
          ) : (
            <button onClick={() => setAdd(true)} className="btn btn-primary">
              Create a new feature
            </button>
          )}
        </div>
        <div className="features">
          {features &&
            features.length > 0 &&
            features.sort().map((feature) => {
              return (
                <div key={feature._id}>
                  <EditComponent feature={feature} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Features;
