import "./App.css";
import Admin from "./components/Admin/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminSignIn from "./components/Login/Login";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { CHECK_LOGIN } from "./redux/constants";
function App() {
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");
  const user = cookies.get("user");
  const dispatch = useDispatch();
  useEffect(() => {
    if (jwt && user) {
      dispatch({ type: CHECK_LOGIN, payload: jwt });
    }
  }, []);
  return (
    <div className="App">
      <Router>
        <ProtectedRoute exact path="/">
          <Admin />
        </ProtectedRoute>

        <Route exact path="/login" component={AdminSignIn} />
      </Router>
    </div>
  );
}

export default App;
