import React, { useState } from "react";
import DealerListing from "./DealerListing";
import EditDealerListing from "./EditDealerListing";
import Features from "./Features";
import Packages from "./Packages";
import "./style.css";
import VehicleListing from "./VehicleListing";
import AddOrEdit from "./AddOrEditVehicleListing";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const Admin = () => {
  const [selected, setSelected] = useState("vehicle-listing");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedWav, setSelectedWav] = useState("");

  return (
    <div id="profile">
      <div className="admin-main-header">
        <div className="content">
          <div className="icon">WAV COMPARE</div>
          <h3>Admin Dashboard</h3>
        </div>
      </div>
      <div className="profile-main">
        <div className="row">
          <div className="col-sm-12 col-md-2 admin-dashboard-side ">
            <div className="admin-dashboard-side-items">
              <h3>VEHICLE LISTINGS</h3>
              <div onClick={() => setSelected("vehicle-listing")}>
                ALL LISTINGS
              </div>
              <div onClick={() => setSelected("add-vehicle-listing")}>
                ADD NEW
              </div>
            </div>
            <div className="admin-dashboard-side-items">
              <h3>DEALER LISTINGS</h3>
              <div onClick={() => setSelected("dealer-listing")}>
                ALL DEALERS
              </div>
              <div onClick={() => setSelected("add-dealer-listing")}>
                ADD NEW
              </div>
            </div>
            <div className="admin-dashboard-side-items">
              <h3>OTHERS</h3>
              <div onClick={() => setSelected("features")}>ALL FEATURES</div>
              <div onClick={() => setSelected("packages")}>PACKAGES</div>
            </div>
          </div>
          <div className="col-sm-12 col-md-10 dashboard-main">
            {selected === "add-dealer-listing" && (
              <div className="edit-dealer-listing">
                <EditDealerListing type="add" setSelected={setSelected} />
              </div>
            )}
            {selected === "edit-dealer-listing" && (
              <div className="edit-dealer-listing">
                {selectedUser !== "" && (
                  <EditDealerListing
                    type="edit"
                    setSelected={setSelected}
                    selectedUser={selectedUser}
                  />
                )}
              </div>
            )}
            {selected === "dealer-listing" && (
              <div className="dealer-listing">
                <DealerListing
                  setSelected={setSelected}
                  setSelectedUser={setSelectedUser}
                  url={url}
                />
              </div>
            )}
            {selected === "edit-vehicle-listing" && (
              <div className="edit-vehicle-listing">
                <AddOrEdit
                  name="editing"
                  wav={selectedWav}
                  setSelected={setSelected}
                />
              </div>
            )}
            {selected === "add-vehicle-listing" && (
              <div className="edit-vehicle-listing">
                <AddOrEdit name="adding" setSelected={setSelected} />
              </div>
            )}
            {selected === "vehicle-listing" && (
              <div className="vehicle-listing">
                <VehicleListing
                  url={url}
                  setSelectedWav={setSelectedWav}
                  setSelected={setSelected}
                />
              </div>
            )}
            {selected === "features" && (
              <div className="features">
                <Features url={url} setSelected={setSelected} />
              </div>
            )}
            {selected === "packages" && (
              <div className="packages">
                <Packages url={url} setSelected={setSelected} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
